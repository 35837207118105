<template>
    <section>
        <InsideHeader :title="pageData.page_heading" :bredcrumb="breadcrumb" />     
        <div class="section course-page">
            <div class="container">
                <div class="heading_main text_align_center">
                    <h2>{{ pageData.page_heading }}</h2>
                </div>
                <div class="courselist_wrap clearfix">
                    <Sidebar/>
                    <div class="course_right clearfix">
                        <div class="spinner-grow d-none" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="course_right_inner" id="courses_grid">
                            <CourseCard :courses="courseData" box="col-sm-4" />     
                            <div class="col-sm-12 d-none">
                                <button class="btn-md btn-theme gradient-btn btn" type="button">Load More</button>
                            </div>       
                        
                            <div class="row" v-if="courseData.length < 1">
                                <div class="no-record">
                                    <i class="icon-material-outline-school"></i>
                                    <p>{{ noCourse }}</p>
                                </div>
                            </div>                     
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import $ from 'jquery'
import InsideHeader from "@/components/InsideHeader.vue";
import Sidebar from "@/components/Course/Sidebar.vue";
import CourseCard from "@/components/Course/CourseCard.vue";
import Translation from '../../public/translation.json'

export default {
    components: {
        InsideHeader, Sidebar, CourseCard
    },
    data(){
        return {
            breadcrumb: [],
            filePath: '',
            pageData: [],
            courseData: [],
            dir_path: {},
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },   
    computed: {
        noCourse(){
            return Translation.content[this.defaultLang].no_course_found
        },
    },
    methods: {
        setBreadCrumb(){
            this.breadcrumb = [
                {title: this.pageContent('home'), path:'/'},
                {title: this.pageContent('courses'), path:'#'}                
            ]
        },
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        getPageData() {
            let slug = 'courses'
            this.$store.dispatch('getPageData', slug).then(res=>{
               if(res.data.status === true) {
                    this.pageData = res.data.data
                    if(res.data.dir_path) {
                        this.dir_path = res.data.dir_path
                    }
                }
            })
            .catch(err => console.log(err))
        },
        getCourses() {
            let dispatch = 'getCourses'
            let params = {};
            // if(this.$store.getters.storeToken)
            //     dispatch = 'userCourses';

            if(this.$route.params && this.$route.params.id) {                
                params.category_id = this.$route.params.id
            }
            else if(this.$route.query && this.$route.query.category) {
                params.category_id = this.$route.query.category
            }
            if(this.$route.query && this.$route.query.srch) {
                params.srch = this.$route.query.srch
            }
            this.$store.dispatch(dispatch, {params}).then(res=>{
                if(res.data.status === true) {
                    this.courseData = res.data.data;
                    if(res.data.path){
						this.dirPath = res.data.path
                    } 
                    if(this.$route.params.id && screen.width <= 480){
                        $('html, body').animate({
                            scrollTop: $("#courses_grid").offset().top -200
                        },1000); 
                    }			
                }
            })
            .catch(err=>{
                if(err.response.status === 404){
                    this.courseData = []
                }
                console.log(err.response.data.error)
            })
        }
    },
    created: function(){
        this.getCourses()
        this.getPageData()
        this.setBreadCrumb()
    },
    watch: {
        "$route.params.id": function() {
            this.getCourses()
        }
    }
}
</script>