<template>
    <div class="sidebar_box course_category sidebar-area">
       
        <!-- <div class="search-area">
            <h3 class="title">Search Courses</h3>
            <div class="box-search">
                <input class="form-control" placeholder="Search Here ..." name="srch-term" id="srch-term" type="text">
                <button class="btn btn-default" type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
            </div>
        </div> -->

        <Category/>
        <!-- <LatestCourse/> -->

        <!-- <div class="tags-cloud clearfix">
            <h3 class="title">Product Tags</h3>
            <ul>
                <li>
                    <a href="#">SCIENCE</a>
                </li>                
            </ul>
        </div> -->

    </div>
</template>

<script>
import Category from './Category'
import LatestCourse from './LatestCourse'

export default {
    components: {
        Category,
        LatestCourse
    }
}
</script>