<template>
    <div class="latest-courses" v-if="courseData.length > 0">
        <h3 class="title">{{ pageContent('latest_courses') }}</h3>
        
        <div class="post-item" v-for="(item, index) of courseData" :key="index">
            <div class="post-img">
                <router-link :to="`/user/course/${item.id}`">
                    <img :src="item.image_name" :alt="item.title">
                </router-link>
            </div>
            <div class="post-desc">
                <h4><router-link :to="`/user/course/${item.id}`">{{ item.title }}</router-link></h4>
                <!-- <span>{{ item.category }}</span> -->
                <div>  
                    <div class="star-rating">
                        <span class="star" v-for="index in item.avg_ratings" :key="index" ></span> 
                        <template v-if="item.avg_ratings < 5">      
                            <span class="star empty" v-for="n in (5 - item.avg_ratings)" :key="n+'_r'"></span>     
                        </template>    
                    </div>
                </div>
                <!-- <div class="course_content" v-if="item.short_content !== null" v-html="item.short_content.substring(0, 50)"></div> -->
                <div class="course_content" v-if="item.short_content !== null" v-html="item.short_content"></div>
                <!-- <span class="duration"> 
                    <i class="fa fa-clock-o" aria-hidden="true"></i> 4 Years
                </span>  -->
                <!-- <span class="price">Price: <span>$350</span></span> -->
            </div>
        </div>
        
    </div>
</template>

<script>
import Translation from '../../../public/translation.json'
export default {
    data() {
        return {
            courseData: [],
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },
    methods: {
        pageContent(field) {
            return Translation.content[this.defaultLang][field]
        },
        getCourses() {           
            this.$store.dispatch('getLatestCourses').then(res=>{
                if(res.data.status === true) {
                    this.courseData = res.data.data;
                }
            })
            .catch(err=>{
                console.log(err.response.data.error)
            })
        }
    },
    created: function(){
        this.getCourses()
    }
}
</script>

<style scoped>
.latest-courses .course_content >>> p {
    line-height: 1.9;
}
</style>