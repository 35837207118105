<template>
    <div class="cate-box">
        <h3 class="title">{{ pageContent('categories') }}</h3>
        <ul>
            <li v-for="(item, index) of categoryData" :key="index">
                <i class="icon-feather-chevron-right" aria-hidden="true"></i> 
                <router-link :to="`/category/${item.id}/courses`">{{ item.title }} <span>({{item.course_count}})</span></router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import Translation from '../../../public/translation.json'

export default {    
    data() {
        return {
            categoryData: [],
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },
    methods: {
        pageContent(field) {
            return Translation.content[this.defaultLang][field]
        },
        getCategories(){
            let dispatch = 'courseCategories'
            // if(this.$store.getters.storeToken)
            //     dispatch = 'userCourseCategories';

            this.$store.dispatch(dispatch)
            .then(res=>{
                if(res.data.status === true)
                    this.categoryData = res.data.data
            })
            .catch(err=>console.log(err.response))
        }
    },
    created: function(){
        this.getCategories()
    }
}
</script>